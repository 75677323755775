import React from "react";
import "./NavBarBottom.module.scss";

export function NavBarBottom() {
    return (
        <div styleName="nav-bar-bottom">
            &copy; Российская Ассоциация Буддистов Алмазного Пути Традиции Карма Кагью
        </div>
        )
;
}