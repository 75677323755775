import {configureStore} from "@reduxjs/toolkit";
import projectsReducer from "./projectsSlice";
import authReducer from "./authSlice";
import {apiSlice} from "./apiSlice";

export default configureStore({
    reducer: {
        projects: projectsReducer,
        auth: authReducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiSlice.middleware)
})