import React from "react";
import {PanelDefault} from "../PanelDefault";
import {BootstrapLayout} from "../BootstrapLayout";
import {Link} from "react-router-dom";

export const ReturnInfo = () => (
    <BootstrapLayout><PanelDefault>
        <Link to="/"><h4>Назад</h4></Link>
        <p><b>Дорогие друзья!</b></p>
        <p>В случае возникновения необходимости вернуть оплату, пишите на почту <a href = "mailto: ombuddhahung@gmail.com">ombuddhahung@gmail.com</a></p>
        <p>Срок рассмотрения заявки на возврат денежных средств составляет 30 дней. Возврат денежных средств осуществляется на ту же банковскую карту, с которой производился платеж. Возврат денежных средств на карту осуществляется в срок от 5 до 30 банковских дней, в зависимости от Банка, которым была выпущена банковская карта.</p>
    </PanelDefault></BootstrapLayout>
);