import React from "react";
import {createRoot} from "react-dom/client";
import store from '../../bootstrap3/app/store'
import "./index.scss";
import $ from "jquery/dist/jquery.min";
import axios from "axios";
import {projectsLoaded} from "../../bootstrap3/app/projectsSlice";
import {currentUserChanged} from "../../bootstrap3/app/authSlice";
import urls from "../../data/urls";
import {BrowserRouter} from "react-router-dom";
import {App} from "./App";

window.jQuery = window.$ = $;
require('bootstrap/dist/js/bootstrap');

const insert_adopted_style_sheet = (name, adopted_style_sheet) => {
    // default_css.disabled = true;
    // let filtered = document.adoptedStyleSheets.filter(sheet => sheet !== document.default_css);
    // document.adoptedStyleSheets = [...filtered, default_css];
    // document.default_css = default_css;
    document.namedAdoptedStyleSheets ??= {}
    adopted_style_sheet.disabled = true;
    const style_sheet_before_hot_module_reloading = document.namedAdoptedStyleSheets[name];
    let filtered = document.adoptedStyleSheets.filter(sheet => sheet !== style_sheet_before_hot_module_reloading);
    document.adoptedStyleSheets = [...filtered, adopted_style_sheet];
    document.namedAdoptedStyleSheets[name] = adopted_style_sheet;
};
// insert_adopted_style_sheet("default", default_css);

const extract_bootstrap_link_element = () => {
    const get_bootstrap_link_element = () => {
        let elementsByTagName = document.getElementsByTagName('link');
        let arr = Array.from(elementsByTagName);
        return arr.filter((link) => {
            return link.href.split('/').some((part) => part.match('bootstrap\..+\.css'));
        })[0];
    };
    document.bootstrap = get_bootstrap_link_element();
};


// const externalStyles = document.createElement('style');
// externalStyles.adoptedStyleSheets = [bootstrap];
// // If you want adopted styles after everything else you've imported:
// document.head.appendChild(externalStyles);
// document.head.insertBefore(externalStyles, document.head.firstElementChild);


(window.__DATA_LOADER_CACHE__?.projects || axios.get(urls.flask.projects,))
    .then((result) => {
        // console.log("projects")
        if (result && result.status === 200) {
            store.dispatch(projectsLoaded(result.data));
        }
    }).catch(e => console.log(e));

(window.__DATA_LOADER_CACHE__?.currentUser || axios.get(urls.flask.current_user,))
    .then((result) => {
        // console.log("users")
        // console.log(result)
        if (result && result.status === 200) {
            store.dispatch(currentUserChanged(result.data));
        }
    }).catch(e => console.log(e))

// const BootApp = () => {
//     // document.adoptedStyleSheets = [bootstrap]
//     // bootstrap.name = 'bootstrap';
//     // document.adoptedStyleSheets = [bootstrap,...document.adoptedStyleSheets, ];
//     // console.log(document.adoptedStyleSheets.flat());
//     return (
//         <React.StrictMode>
//             <Provider store={store}>
//                 <span style={{"visibility": "hidden", "position": "absolute"}} className={"glyphicon glyphicon-user"}>gliphicon preloader</span>
//                 {/*<Boot/>*/}
//                 <Routes/>
//             </Provider>
//         </React.StrictMode>);
// };

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<React.StrictMode>
    <BrowserRouter>
        <App/>
    </BrowserRouter>
</React.StrictMode>);
// ReactDOM.render((
//     <React.StrictMode>
//         <BrowserRouter>
//             <App/>
//         </BrowserRouter>
//     </React.StrictMode>), document.getElementById("root"));

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//     hydrate(<App />, rootElement);
// } else {
//     render(<App />, rootElement);
// }

// console.log("bootstrap");
export default App;