import React from "react";
import {PanelDefault} from "../PanelDefault";
import {BootstrapLayout} from "../BootstrapLayout";
import {Link} from "react-router-dom";

export const Garanty = () => (
    <BootstrapLayout><PanelDefault>
        <Link to="/"><h4>Назад</h4></Link>
        <p><b>Дорогие друзья!</b></p>
        <p>Вы можете оплатить свой заказ онлайн с помощью банковской карты через платежный сервис компании
            Uniteller. После подтверждения заказа Вы будете перенаправлены на защищенную платежную страницу
            Uniteller, где необходимо будет ввести данные для оплаты заказа. После успешной оплаты на указанную в
            форме оплаты электронную почту будет направлен электронный чек с информацией о заказе и данными по
            произведенной оплате.</p>
        <p><b>Гарантии безопасности</b></p>
        <p>Безопасность процессинга Uniteller подтверждена сертификатом стандарта безопасности данных индустрии
            платежных карт PCI DSS. Надежность сервиса обеспечивается интеллектуальной системой мониторинга
            мошеннических операций, а также применением 3D Secure - современной технологией безопасности
            интернет-платежей.</p>
        <p>Данные Вашей карты вводятся на специальной защищенной платежной странице. Передача информации в
            процессинговую компанию Uniteller происходит с применением технологии шифрования TLS. Дальнейшая
            передача информации осуществляется по закрытым банковским каналам, имеющим наивысший уровень
            надежности.</p>
        <p><b>Uniteller не передает данные Вашей карты магазину и иным третьим лицам!</b></p>

        <p>Если Ваша карта поддерживает технологию 3D Secure, для осуществления платежа, Вам необходимо будет пройти
            дополнительную проверку пользователя в банке-эмитенте (банк, который выпустил Вашу карту). Для этого Вы
            будете направлены на страницу банка, выдавшего карту. Вид проверки зависит от банка. Как правило, это
            дополнительный пароль, который отправляется в SMS, карта переменных кодов, либо другие способы.</p>
    </PanelDefault></BootstrapLayout>
);